<template>
  <div>
    <div id="header">
      <div class="d-flex flex-row justify-content-around logo">
        <img
          src="@/assets/skl-logo.png"
          alt="Smiley face"
          height="27"
          width="70"
          class="mb-2"
        />
      </div>
      <div id="header-title" class="text-center title">
        <b>ชำระเงินออนไลน์</b>
      </div>
    </div>
    <div class="body f-14" id="content-payment">
      <div>
        <div class="header-detail">
          <p class="font-weight-bold mb-2">รายละเอียดการชำระเงิน</p>
        </div>
        <div class="from-detail">
          <div class="row detail-item">
            <div class="col-5">
              <p class="mb-1 font-weight-bold">ชื่อ - สกุล</p>
            </div>
            <div class="col-7">{{ customerProfile.CustomerName }}</div>
          </div>
          <div class="row detail-item">
            <div class="col-5">
              <p class="mb-1 font-weight-bold">เลขที่สัญญา</p>
            </div>
            <div class="col-6">{{ contractNo }}</div>
          </div>
          <div class="row detail-item">
            <div class="col-5">
              <p class="mb-1 font-weight-bold">เลขที่อ้างอิง Ref.1</p>
            </div>
            <div class="col-6">{{ numberRef }}</div>
          </div>
          <div
            v-if="!['SP', 'L'].includes(customerProfile.CollectionStatusUnits)"
            class="row detail-item"
          >
            <div class="col-5">
              <p class="mb-1 font-weight-bold">ยอดที่ต้องชำระ</p>
            </div>
            <div class="col-6" v-if="!this.$isLoadingAlpha">
              {{
                paymentAmount.toLocaleString("en", {
                  minimumFractionDigits: 2
                })
              }}
              บาท
            </div>
          </div>
          <div class="mt-1 mb-2 form-inline">
            <div
              v-if="
                !['SP', 'L'].includes(customerProfile.CollectionStatusUnits)
              "
              :class="['mr-2', { 'mb-4': $v.form.total.$error }]"
            >
              <svg
                class="svg-check-box"
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                @click="form.isEdit = !form.isEdit"
              >
                <g
                  id="Rectangle_1784"
                  data-name="Rectangle 1784"
                  fill="#fff"
                  stroke="#707070"
                  stroke-width="1"
                >
                  <rect width="23" height="23" rx="3" stroke="none" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22"
                    height="22"
                    rx="2.5"
                    fill="none"
                  />
                </g>
                <path
                  id="Path_8841"
                  data-name="Path 8841"
                  d="M-18087.189-17236.355l5.17,5.17,6.658-11"
                  transform="translate(18093 17248)"
                  fill="none"
                  stroke="#80c141"
                  stroke-linecap="round"
                  stroke-width="2"
                  v-if="form.isEdit"
                />
              </svg>
            </div>
            <div class="flex-grow-1">
              <InputTextBox
                id="input-1"
                v-model="form.total"
                :placeholder="
                  !['SP', 'L'].includes(customerProfile.CollectionStatusUnits)
                    ? 'แก้ไขยอดที่ต้องการชำระ'
                    : 'กรุณาระบุยอดเงินที่ต้องการชำระ'
                "
                type="text"
                inputmode="decimal"
                :isError="form.isError"
                :isDisabled="!form.isEdit"
                inputChecker="currency"
              />
              <div
                id="input-error"
                class="pl-2 mt-1"
                v-if="$v.form.total.$error"
              >
                <font color="red">ยอดเงินชำระขั้นต่ำ 1 บาท</font>
              </div>
            </div>
            <!-- :changeColorPlaceholder=" ['SP',
            'L'].includes(customerProfile.CollectionStatusUnits) " -->
          </div>
        </div>
        <div class="payment mt-2">
          <p class="mb-0 font-weight-bold">
            เลือกธนาคารที่ท่านต้องการใช้บริการ
          </p>
          <p class="f-small-gray">
            กรุณาเข้าสู่ระบบโมบายแบงก์กิ้งของท่าน
            เพื่อดำเนินการชำระเงินให้สมบรูณ์
          </p>
          <div class="from-detail">
            <div class="detail-item">
              <b-form-group>
                <b-form-radio-group
                  v-model="form.bankId"
                  v-for="(option, index) in options"
                  v-bind:key="index"
                  size="sm"
                  stacked
                  plain
                  class="font-weight-bold"
                >
                  <b-form-radio
                    :value="index"
                    class="mb-1 d-flex"
                    :style="{ 'font-size': '16px' }"
                  >
                    <img :src="option.imageUrl" class="icon-bank" />
                    {{ option.name }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <!-- <div id="missing-input-error" class="hidden">
          <font color="red">กรุณาตรวจสอบความถูกต้องอีกครั้ง</font>
      </div>-->
      <br />
      <div class="text-center">
        <b-button
          id="submit-payment"
          variant="submit"
          type="button"
          @click="submitForm"
          :disabled="buttonLoading"
        >
          <b-spinner small v-if="buttonLoading"></b-spinner>
          <span v-else>ชำระเงิน</span>
        </b-button>
      </div>
      <b-modal id="bankAlert" hide-footer>
        <div class="d-block text-center">
          กรุณาเลือกธนาคาร
        </div>
        <b-button class="mt-3" block @click="$bvModal.hide('bankAlert')"
          >ตกลง</b-button
        >
      </b-modal>
    </div>
    <div
      v-if="(state = 'payPage')"
      :style="{
        position: 'absolute',
        top: '-400px',
        'z-index': '-10',
        opacity: 0
      }"
    >
      <form :action="pay_onlineResponse.PostUrl" method="post">
        <input
          type="text"
          name="Customer"
          :value="pay_onlineResponse.Customer"
        />
        <input type="text" name="Ref1" :value="pay_onlineResponse.Ref1" />
        <input
          type="text"
          name="ContacNo"
          :value="pay_onlineResponse.ContacNo"
        />
        <input
          type="text"
          name="TranSactionNo"
          :value="pay_onlineResponse.TranSactionNo"
        />
        <input type="text" name="Amount" :value="pay_onlineResponse.Amount" />
        <input
          type="text"
          name="ProductInfo"
          :value="pay_onlineResponse.ProductInfo"
        />
        <input
          type="text"
          name="TranSactionDate"
          :value="pay_onlineResponse.TranSactionDate"
        />
        <input
          type="text"
          name="BankGate"
          :value="pay_onlineResponse.BankGate"
        />
        <input type="text" name="ChannelID" value="2" />
        <button ref="submit">submit</button>
      </form>
    </div>
  </div>
</template>

<script>
const InputTextBox = () => import("@/components/inputs/InputTextBox-New");
import { required, requiredIf, decimal } from "vuelidate/lib/validators";

export default {
  components: {
    InputTextBox
  },
  validations() {
    return {
      form: {
        total: {
          required: requiredIf(function() {
            return this.form.isEdit;
          })
        },
        bankId: { required, decimal }
      }
    };
  },
  data() {
    return {
      userData: {},
      buttonLoading: false,
      form: {
        bankId: null,
        total: "",
        isEdit: false,
        isError: false
      },
      contractNo: this.$route.query.contractNo,
      type: this.$route.query.type,
      amount: this.$route.query.amount,
      options: [],
      contractInfo: {},
      customerProfile: {
        ContractNo: "",
        Model: "",
        CompulsoryRenewalDate: "",
        LicenceNo: "",
        CustomerName: "",
        CollectionStatusUnits: "",
        AsOfDate: "",
        IDCard: "",
        Point: 0
      },
      paymentAmount: 0,
      state: "form",
      payPageHTML: "",
      pay_onlineResponse: {},
      lineProfile: {},
      numberRef: ""
    };
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Payment })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login({ redirectUri: window.location.href }).then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.checkTerm(profile.userId);
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    if (!this.contractNo) {
      const queryString = decodeURIComponent(window.location.search).replace(
        "?liff.state=",
        ""
      );
      const params = new URLSearchParams(queryString);
      if (params.has("contractNo")) {
        const contractNo = params.get("contractNo");

        this.contractNo = contractNo;
      } else {
        const queryString = decodeURIComponent(window.location.search).replace(
          "liff.state=?",
          ""
        );
        const params = new URLSearchParams(queryString);
        const contractNo = params.get("contractNo");

        this.contractNo = contractNo;
      }
    }

    this.sendNoti();
    this.getCustomerProfile();
    this.getContactBarcode();
    //CALL INSTALLMENT
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Authorization: this.$NIMBUS_TOKEN,
      RequestBody: JSON.stringify({
        ContractNo: this.contractNo
      }),
      Path: `${this.$API_DEV_SKL}/contract_installment`,
      Method: "POST"
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.result) {
          this.contractInfo = result.data;

          this.contractInfo.OverdueAmount =
            result.data.OverdueAmount == ""
              ? 0
              : parseFloat(result.data.OverdueAmount);
          this.contractInfo.PenaltyAmt =
            result.data.PenaltyAmt == ""
              ? 0
              : parseFloat(result.data.PenaltyAmt);
          this.contractInfo.CallCharge =
            result.data.CallCharge == ""
              ? 0
              : parseFloat(result.data.CallCharge);
          this.contractInfo.RIPaymentAmount =
            result.data.RIPaymentAmount == ""
              ? 0
              : parseFloat(result.data.RIPaymentAmount);
          this.contractInfo.TaxPaymentAmount =
            result.data.TaxPaymentAmount == ""
              ? 0
              : parseFloat(result.data.TaxPaymentAmount);
          this.contractInfo.TransferPaymentAmount =
            result.data.TransferPaymentAmount == ""
              ? 0
              : parseFloat(result.data.TransferPaymentAmount);
          this.contractInfo.NextPaymentAmount =
            result.data.NextPaymentAmount == ""
              ? 0
              : parseFloat(result.data.NextPaymentAmount);

          switch (this.type) {
            case "OverdueAmount":
              this.paymentAmount = this.contractInfo.OverdueAmount;
              break;
            case "PenaltyAmt":
              this.paymentAmount = this.contractInfo.PenaltyAmt;
              break;
            case "CallCharge":
              this.paymentAmount = this.contractInfo.CallCharge;
              break;
            case "RIPaymentAmount":
              this.paymentAmount = this.contractInfo.RIPaymentAmount;
              break;
            case "TaxPaymentAmount":
              this.paymentAmount = this.contractInfo.TaxPaymentAmount;
              break;
            case "TransferPaymentAmount":
              this.paymentAmount = this.contractInfo.TransferPaymentAmount;
              break;
            case "NextPaymentAmount":
              this.paymentAmount = this.contractInfo.NextPaymentAmount;
              break;
            default:
              this.paymentAmount =
                this.contractInfo.OverdueAmount +
                this.contractInfo.PenaltyAmt +
                this.contractInfo.CallCharge +
                this.contractInfo.RIPaymentAmount +
                this.contractInfo.TaxPaymentAmount +
                this.contractInfo.TransferPaymentAmount +
                this.contractInfo.NextPaymentAmount;
              break;
          }
          if (this.amount) {
            this.paymentAmount = this.amount;
          }
        }
      })
      .catch(error => {
        console.error(error);
      });

    // CALL BANK
    var myHeadersBankList = new Headers();
    myHeadersBankList.append("API-KEY", "S1112LIN05!");

    var requestOptionsBankList = {
      method: "GET",
      headers: myHeadersBankList,
      redirect: "follow"
    };

    fetch(
      `${this.$API_DEV_LINE_SKL}/Bank/OnlinePayment`,
      requestOptionsBankList
    )
      .then(response => response.json())
      .then(result => {
        if (result.result == 1) {
          this.options = result.detail;
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  mounted() {
    this.$isLoadingAlpha = true;
    var loop = setInterval(() => {
      console.log(this.lineProfile.userId);
      if (this.lineProfile.userId != undefined) {
        this.checkIsPayment();
        clearInterval(loop);
      }
    }, 500);
  },
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async checkIsPayment() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        lineId: this.lineProfile.userId,
        // contractNo: this.contractNo
        contractNo: this.contractNo
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkIsPayment`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (result.detail.status == 1) {
              document.getElementById(
                "content-payment"
              ).innerHTML = `<div class="content_center">กรุณาลงทะเบียนก่อนใช้บริการชำระเงินออนไลน์</div>`;
              this.$isLoadingAlpha = false;
            } else if (result.detail.status == 2) {
              document.getElementById(
                "content-payment"
              ).innerHTML = `<div class="content_center">เลขที่สัญญาไม่ตรงกับบัญชีของคุณ</div>`;
              this.$isLoadingAlpha = false;
            } else if (result.detail.status == 3) {
              this.$isLoadingAlpha = false;
            }
          } else {
            document.getElementById(
              "content-payment"
            ).innerHTML = `<div class="content_center">เกิดปัญหาในการเรียก API (result=0)</div>`;
            this.$isLoadingAlpha = false;
          }
        })
        .catch(error => {
          document.getElementById(
            "content-payment"
          ).innerHTML = `<div class="content_center">เกิดปัญหาในการเรียก API (catch)</div>`;
          console.log("error", error);
          this.$isLoadingAlpha = false;
        });
    },
    getCustomerProfile() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contractNo
        }),
        Path: `${this.$API_DEV_SKL}/contract_data`,
        Method: "POST"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.customerProfile = result.data;
            if (
              ["SP", "L"].includes(this.customerProfile.CollectionStatusUnits)
            ) {
              this.form.isEdit = true;
            }
          }
        })
        .catch(error => console.error(error));
    },
    getContactBarcode() {
      // CALL BARCODE (Ref1)

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contractNo
        }),
        Path: `${this.$API_DEV_SKL}/contract_barcode`,
        Method: "POST"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.numberRef = result.data.BankBarcodeRef1;
            this.changeNumberRef(result.data.BankBarcodeRef1);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    changeNumberRef(numberRef) {
      let indexContractNo = numberRef.indexOf(this.contractNo);
      let lengthContractNo = this.contractNo.length;
      this.numberRef = numberRef.substring(
        0,
        indexContractNo + lengthContractNo
      );
    },
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.bankId.$error) {
        this.$bvModal.show("bankAlert");
        return;
      } else if (this.$v.form.$error) {
        return;
      }
      this.form.isError = false;
      var userPay;
      if (this.form.isEdit) {
        if (parseFloat(this.form.total) < 1.0) {
          this.form.isError = true;
          return;
        } else {
          userPay = this.form.total.toString();
        }
      } else {
        userPay = this.paymentAmount.toString();
      }

      this.buttonLoading = true;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contractNo,
          PaymentBank: this.options[this.form.bankId].code,
          PaymentAmount: parseFloat(userPay.replace(/,/g, "")).toString(),
          IdCard: this.customerProfile.IDCard,
          ChannelID: 2
        }),
        Path: `${this.$API_DEV_SKL}/payment_online`,
        Method: "POST",
        type: this.type,
        LineId: this.lineProfile.userId
      });

      console.log(
        "RequestBody PortalPaymentOnline: " +
          JSON.stringify({
            ContractNo: this.contractNo,
            PaymentBank: this.options[this.form.bankId].code,
            PaymentAmount: parseFloat(userPay.replace(/,/g, "")).toString(),
            IdCard: this.customerProfile.IDCard,
            ChannelID: 2
          })
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/PortalPaymentOnline`, requestOptions)
        .then(response => response.json())
        .then(result => {
          // console.log(result);
          this.buttonLoading = false;
          if (result.result) {
            this.pay_onlineResponse = result.data;
            console.log(
              "result PortalPaymentOnline" + JSON.stringify(result.data)
            );
            this.state = "payPage";
            this.$nextTick(() => {
              this.$refs.submit.click();
              this.form.bankId = null;
            });
          } else {
            this.$bvModal.show("modal-tryagain");
          }
        })
        .catch(error => {
          this.buttonLoading = false;
          this.$bvModal.show("modal-tryagain");
          console.error(error);
        });
    },
    goPaymentPage() {
      console.log("goPaymentPage");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          Customer: this.pay_onlineResponse.Customer,
          Ref1: this.pay_onlineResponse.Ref1,
          ContacNo: this.pay_onlineResponse.ContacNo,
          TranSactionNo: this.pay_onlineResponse.TranSactionNo,
          Amount: this.pay_onlineResponse.Amount,
          ProductInfo: this.pay_onlineResponse.ProductInfo,
          TranSactionDate: this.pay_onlineResponse.TranSactionDate,
          BankGate: this.pay_onlineResponse.BankGate,
          ChannelID: this.pay_onlineResponse.ChannelID,
          CHECKVAL: this.pay_onlineResponse.CHECKVAL
        }),
        Path: this.pay_onlineResponse.PostUrl,
        Method: "POST"
      });

      console.log("path field PostUrl: " + this.pay_onlineResponse.PostUrl);
      console.log(
        "RequestBody Portal: " +
          JSON.stringify({
            Customer: this.pay_onlineResponse.Customer,
            Ref1: this.pay_onlineResponse.Ref1,
            ContacNo: this.pay_onlineResponse.ContacNo,
            TranSactionNo: this.pay_onlineResponse.TranSactionNo,
            Amount: this.pay_onlineResponse.Amount,
            ProductInfo: this.pay_onlineResponse.ProductInfo,
            TranSactionDate: this.pay_onlineResponse.TranSactionDate,
            BankGate: this.pay_onlineResponse.BankGate,
            ChannelID: this.pay_onlineResponse.ChannelID,
            CHECKVAL: this.pay_onlineResponse.CHECKVAL
          })
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
        .then(response => response.text())
        .then(result => {
          this.payPageHTML = result;
          this.$nextTick(() => {
            this.state = "payPage";
          });
        })
        .catch(error => {
          console.error(error);
        });
    },
    sendNoti() {
      // SEND Noti
      var myHeadersNoti = new Headers();
      myHeadersNoti.append("Content-Type", "application/json");

      var rawNoti = JSON.stringify({
        Type: this.type,
        LineId: this.lineProfile.userId,
        ContractNo: this.contractNo
      });

      var requestOptionsNoti = {
        method: "POST",
        headers: myHeadersNoti,
        body: rawNoti,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/Payment/NotiOnlinePayment`,
        requestOptionsNoti
      )
        .then(response => response.json())
        .then(result => {
          return result;
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped>
.icon-bank {
  height: 22px;
  border-radius: 3px;
}
.form-group {
  margin-bottom: 0rem;
}
.content_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
